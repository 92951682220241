import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styles from './FactList.module.scss'

class FactList extends PureComponent {
    render() {
        const {facts} = this.props
        return (
            <div className={styles.Main}>
                {facts.map(({fact}, index) => <h4 key={index} className={styles.Item}>{fact}</h4>)}
            </div>
        )
    }
}

FactList.propTypes = {
    facts: PropTypes.array.isRequired
}

export default FactList
