import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Project from '../components/projects/Project'

export default ({data: {project, projects}, pageContext, location}) => {

    if (!project) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    const {title, text, websites, pictures, facts, random_images} = project.data

    return (
        <Layout
            location={location}
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}>
            <Project
                locale={pageContext.locale}
                slug={project.slugs[0]}
                backButtonText={projects.data.back_button_text}
                headline={title}
                text={text}
                pictures={pictures}
                facts={facts}
                websites={websites}
                randomImages={random_images}/>
        </Layout>
    )
}

export const query = graphql`
query projectQuery($id: String!, $lang: String!) {
  project:prismicProject(id: {eq: $id }) {
    slugs
    data {
      title {
        text
      }
      pictures {
        picture {
          dimensions {
            width
            height
          }
          alt
          url
        }
      }
      facts {
        fact
      }
      text {
        html
      }
      websites {
        website {
          html
          text
        }
      }
      random_images {
        image {
          dimensions {
            width
            height
          }
          alt
          url
        }
      }
    }
  }
  projects: prismicProjects(lang: {eq: $lang}) {
    data {
      back_button_text
    }
  }
}
`