import React, {PureComponent} from 'react'
import styles from './Gallery.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import FitPic from '../fitPic/FitPic'
import Arrow from '../../svg/arrow'
import {Easing, Tween} from 'es6-tween'
import clamp from 'clamp'
import {BASE_FONT_SIZE, CURSORS, MQ_DESKTOP_SMALL, MQ_TABLET} from '../../Config'
import {AppConsumer} from '../../AppProvider'

class Gallery extends PureComponent {

    constructor(props) {
        super()
        this.onPrevClick = this.onPrevClick.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onWindowTouchMove = this.onWindowTouchMove.bind(this)
        this.onTouchStart = this.onTouchStart.bind(this)
        this.onTouchMove = this.onTouchMove.bind(this)
        this.onTouchEnd = this.onTouchEnd.bind(this)
        this.update = this.update.bind(this)
        this.resize = this.resize.bind(this)
        this.onDragStart = this.onDragStart.bind(this)

        const {items} = props
        this.state = {
            index: 0,
            prevActive: false,
            nextActive: items.length > 1,
        }

        this.deltaX = 0
        this.oldX = 0
        this.x = 0

        this.tween = null
        this.childNodes = []
        this.offsets = []
        this.numChilds = 0
    }

    onPrevClick(event) {
        event.preventDefault()
        this.prev()
    }

    onNextClick(event) {
        event.preventDefault()
        this.next()
    }

    onBeforeSlide(index) {
        const {items} = this.props

        this.setState(state => ({
            prevActive: index > 0,
            nextActive: index < items.length - 1,
        }))
    }

    componentDidMount() {
        window.addEventListener('touchmove', this.onWindowTouchMove, {passive: false})
        window.addEventListener('resize', this.resize)
        this.wrapperNode.addEventListener('touchstart', this.onTouchStart)
        this.wrapperNode.addEventListener('mousedown', this.onTouchStart)
        this.wrapperNode.addEventListener('dragstart', this.onDragStart)
        this.resize()
        this.raf = requestAnimationFrame(this.update)
        this.numChilds = this.childNodes.length
    }

    componentWillUnmount() {
        window.removeEventListener('touchmove', this.onWindowTouchMove)
        window.removeEventListener('resize', this.resize)
        this.wrapperNode.removeEventListener('touchstart', this.onTouchStart)
        this.wrapperNode.removeEventListener('mousedown', this.onTouchStart)
        this.wrapperNode.removeEventListener('dragstart', this.onDragStart)
        this.removeBodyListener()
        cancelAnimationFrame(this.raf)
        this.x = 0
        this.setTransform(0)
    }

    addBodyListener() {
        document.body.addEventListener('touchmove', this.onTouchMove, {passive: false})
        document.body.addEventListener('mousemove', this.onTouchMove, {passive: false})
        document.body.addEventListener('touchcancel', this.onTouchEnd)
        document.body.addEventListener('touchend', this.onTouchEnd)
        document.body.addEventListener('mouseup', this.onTouchEnd)
        document.body.addEventListener('mouseleave', this.onTouchEnd)
    }

    removeBodyListener() {
        document.body.removeEventListener('touchmove', this.onTouchMove)
        document.body.removeEventListener('mousemove', this.onTouchMove)
        document.body.removeEventListener('touchcancel', this.onTouchEnd)
        document.body.removeEventListener('touchend', this.onTouchEnd)
        document.body.removeEventListener('mouseup', this.onTouchEnd)
        document.body.removeEventListener('mouseleave', this.onTouchEnd)
    }

    next() {
        this.slide(clamp(this.state.index + 1, 0, this.numChilds - 1))
    }

    prev() {
        this.slide(clamp(this.state.index - 1, 0, this.numChilds - 1))
    }

    onClick(index, event) {
        event.preventDefault()
        if (index === this.state.index) return
        this.slide(index, 1000)
    }

    onWindowTouchMove(event) {
        if (this.isDragging) event.preventDefault()
    }

    onTouchStart(event) {
        this.tween && this.tween.stop()

        this.isScrolling = false
        this.isTouchstarted = true

        const touches = event.touches ? event.touches[0] : event

        this.startX = touches.clientX
        this.startY = touches.clientY
        this.touchStartTime = performance.now()
        this.deltaX = 0
        this.deltaY = 0
        this.oldX = this.x

        this.addBodyListener()
    }

    onTouchMove(event) {
        if (!this.isTouchstarted) return
        const touches = event.touches ? event.touches[0] : event

        this.deltaX = touches.clientX - this.startX
        this.deltaY = touches.clientY - this.startY

        if (!this.isScrolling) {
            this.isScrolling = Math.abs(this.deltaX) < Math.abs(this.deltaY)
        }

        if (!this.isScrolling) {
            event.preventDefault()
        }

        this.isDragging = this.deltaX && !this.isScrolling
    }

    onTouchEnd(event) {
        this.removeBodyListener()

        this.isDragging = false
        if (!this.deltaX || !this.isTouchstarted) return
        this.isTouchstarted = false

        if (this.isScrolling) {
            this.slide(this.state.index)
            return
        }

        const duration = performance.now() - this.touchStartTime
        const isSwipe = duration < 300 && Math.abs(this.deltaX) > 25 // || Math.abs(this.deltaX) > window.innerWidth / 3;

        if (isSwipe) {
            const direction = this.deltaX < 0
            const index = clamp(direction ? this.state.index + 1 : this.state.index - 1, 0, this.numChilds - 1)
            this.slide(index)
        } else {
            this.snapToNearest()
        }
    }

    onDragStart(event) {
        if (event.target.nodeName.toUpperCase() === 'IMG') {
            event.preventDefault()
        }
    }

    snapToNearest() {
        let index = 0
        let diff = Number.POSITIVE_INFINITY

        this.offsets.forEach((offset, offsetIndex) => {
            const newDiff = Math.abs(offset - this.x)
            if (newDiff < diff) {
                diff = newDiff
                index = offsetIndex
            }
        })

        this.slide(index)
    }

    slide(index) {
        const to = this.offsets[index]
        if (this.x === to) return

        if (this.tween) this.tween.stop()

        const duration = clamp(Math.abs(this.x - to) * 0.75, 500, 1000)

        this.onBeforeSlide(index)
        this.tween = new Tween({x: this.x})
            .to({x: to}, duration)
            .easing(Easing.Exponential.Out)
            .on('start', () => this.setState(state => ({index})))
            .on('update', ({x}) => {
                this.x = x
                this.oldX = x
                this.setTransform()
            })
            .on('complete')
            .start()
    }

    setTransform() {
        if (this.trackNode) this.trackNode.style.transform = `translateX(${this.x}px)`
    }

    resize() {
        this.offsets = this.childNodes.map(childNode => {
            if (!childNode) return 0
            const el = childNode.node ? childNode.node : childNode
            return el.offsetLeft * -1
        })

        const x = this.offsets[this.state.index]
        this.x = x
        this.oldX = x
        this.setTransform()
    }

    update() {
        this.raf = requestAnimationFrame(this.update)
        if (this.isDragging) {
            this.x = this.oldX + this.deltaX
            this.setTransform()
        }
    }

    render() {
        const {items} = this.props
        const {prevActive, nextActive} = this.state

        return (
            <AppConsumer>
                {({showCursor, hideCursor}) => (
                    <section className={styles.Main}>
                        <div className={styles.Inner}>
                            <div className={styles.Slider} ref={node => this.wrapperNode = node}>
                                <div className={styles.Track} ref={node => this.trackNode = node}>

                                    {items.map(({picture}, index) => {

                                            if (!picture.url) return null

                                            const sizes = `
                                    (min-width: ${MQ_TABLET / BASE_FONT_SIZE}em) 75vw,
                                    (min-width: ${MQ_DESKTOP_SMALL / BASE_FONT_SIZE}em) 50vw,
                                    100vw
                                `
                                            return (
                                                <div key={index}
                                                     className={styles.Item}
                                                     ref={node => this.childNodes[index] = node}
                                                     onClick={this.onClick.bind(this, index)}>

                                                    <FitPic
                                                        src={picture.url}
                                                        width={picture.dimensions.width}
                                                        height={picture.dimensions.height}
                                                        ratio={'16:10'}
                                                        sizes={sizes}
                                                        defaultWidth={1250}
                                                    />

                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <div className={cx(styles.ClickArea, {[styles.IsActive]: prevActive})}
                                     onMouseEnter={() => showCursor(CURSORS.ARROW_LEFT_RED)}
                                     onMouseLeave={() => hideCursor()}
                                     onClick={this.onPrevClick}
                                />
                                <div className={cx(styles.ClickArea, styles.Left, {[styles.IsActive]: nextActive})}
                                     onMouseEnter={() => showCursor(CURSORS.ARROW_RIGHT_RED)}
                                     onMouseLeave={() => hideCursor()}
                                     onClick={this.onNextClick}
                                />
                            </div>

                            <div className={styles.Buttons}>
                                <button className={cx(styles.Button, styles.Prev, {[styles.IsActive]: prevActive})}
                                        onClick={this.onPrevClick}>
                                    <Arrow/>
                                </button>

                                <button className={cx(styles.Button, {[styles.IsActive]: nextActive})}
                                        onClick={this.onNextClick}>
                                    <Arrow/>
                                </button>
                            </div>
                        </div>
                    </section>
                )}
            </AppConsumer>
        )
    }
}

Gallery.propTypes = {
    items: PropTypes.array.isRequired
}

export default Gallery
