import React from 'react'
import PropTypes from 'prop-types'
import styles from './FitPic.module.scss'


class FitPic extends React.PureComponent {

    constructor(props) {
        super(props)
        this.onLoad = this.onLoad.bind(this)

        this.state = {
            isLoaded: false,
        }
    }

    onLoad() {
        this.setState(() => ({isLoaded: true}))
    }

    componentDidMount() {
        if (this.node && this.node.complete) {
            this.setState(() => ({isLoaded: true}))
        }
    }

    render() {
        const {altText, sizes, isIntersecting, width, height, ratio, srcSet, src, defaultWidth} = this.props
        const {isLoaded} = this.state

        const srcSetString = srcSet.map((size) => `${src}&w=${size} ${size}w`).join()
        const defaultSrc = `${src}&w=${defaultWidth}`

        let style = null
        if (!ratio) {
            style = {paddingBottom: `${height / width * 100}%`, opacity: isLoaded ? 1 : 0.001}
        } else {
            const ratioArray = ratio.split(':')
            style = {paddingBottom: `${ratioArray[1] / ratioArray[0] * 100}%`, opacity: isLoaded ? 1 : 0.001}
        }

        return (
            <div
                className={styles.aspectRatioBox}
                style={style}>
                <img
                    ref={node => this.node = node}
                    className={styles.image}
                    srcSet={isIntersecting ? srcSetString : ''}
                    src={isIntersecting ? defaultSrc : ''}
                    sizes={sizes}
                    alt={altText}
                    onLoad={this.onLoad}
                />
            </div>
        )
    }
}

export default FitPic


FitPic.propTypes = {
    src: PropTypes.string.isRequired,
    altText: PropTypes.string,
    srcSet: PropTypes.arrayOf(PropTypes.number),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    isIntersecting: PropTypes.bool,
    defaultWidth: PropTypes.number,
    sizes: PropTypes.string,
    ratio: PropTypes.string
}

FitPic.defaultProps = {
    srcSet: [640, 1280, 1440, 2560],
    defaultWidth: 960,
    isIntersecting: true,
    sizes: '100vw',
}

