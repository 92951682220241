import React from 'react'
import PropTypes from 'prop-types'
import styles from './Text.module.scss'

const Text = ({text: {html}}) => (
    <div className={styles.Main} dangerouslySetInnerHTML={{__html: html}}/>
)

export default Text

Text.propTypes = {
    text: PropTypes.shape({
        html: PropTypes.string
    })
}

Text.defaultProps = {
    text: {
        html: ''
    }
}