import React from 'react'
import styles from './Button.module.scss'
import {Link} from 'gatsby'
import Arrow from '../../svg/arrow'
import PropTypes from 'prop-types'

const requiredUrlOrOnClick = (props, propName, componentName) => {
    if (!props.to && !props.onClick) {
        return new Error(`One of props 'to' or 'onClick' was not specified in '${componentName}'.`)
    }
}

const isExternal = (url) => {
    // tel or mailto or https
    if (url.match(/^\w+:/)) return true
    // protocoless
    if (url.match(/^\/\//)) return true

    return false
}

const isMailOrTel = (url) => {
    if (url.match(/^mailto:/)) return true
    if (url.match(/^tel:/)) return true
    return false
}

const Wrapper = ({to, onClick, state, children}) => {
    if (to) {
        return isExternal(to)
            ? <a
                className={styles.Main}
                href={to}
                target={isMailOrTel(to) ? '_self' : '_blank'}
                rel="noopener noreferrer">
                {children}
            </a>
            :
            <Link className={styles.Main} to={to} state={state}>{children}</Link>
    }
    else if (onClick) {
        return <button className={styles.Main} onClick={onClick}>{children}</button>
    }

    return null
}

const Button = (props) => (
    <Wrapper {...props}>
        <span>{props.children}</span>
        <span className={styles.Arrow}><Arrow/></span>
    </Wrapper>
)

Button.propTypes = {
    to: requiredUrlOrOnClick,
    onClick: requiredUrlOrOnClick,
    state: PropTypes.object
}

Button.defaultProps = {}

export default Button
