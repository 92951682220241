import React from 'react'
import PropTypes from 'prop-types'
import styles from './Project.module.scss'
import Headline from '../headline/Headline'
import Container from '../container/Container'
import Text from '../text/Text'
import FactList from './FactList'
import Gallery from './Gallery'
import Draggable from '../draggable/Draggable'
import Button from '../button/Button'
import RandomImages from '../randomImages/RandomImages'
import {Link} from 'gatsby'
import {AppConsumer} from '../../AppProvider'
import {CURSORS} from '../../Config'

const Project = ({backButtonText, headline, text, websites, pictures, facts, locale, randomImages, slug}) => {

    const websiteLinks = websites.map(({website}, index) => {
        if (website && website.html) {
            const href = website.html.match(/href="([^"]*)/)[1]
            return (
                <Draggable
                    key={index}
                    left={Math.random()}
                    top={Math.random()}>
                    <Button to={href}>{website.text}</Button>
                </Draggable>
            )
        } else {
            return null
        }
    })

    return (
        <AppConsumer>
            {({showCursor, hideCursor}) => (
                <div>
                    <div className={styles.Back}
                         onMouseEnter={() => showCursor(CURSORS.ARROW_LEFT_RED)}
                         onMouseLeave={() => hideCursor()}
                    >
                        <Link to={`${locale}/projects`}>{backButtonText || 'Back'}</Link>
                    </div>

                    <Headline headline={headline}/>

                    <div className={styles.Top}>
                        <Container>
                            <Text text={text}/>
                        </Container>
                        {facts && <FactList facts={facts}/>}
                    </div>

                    {pictures &&
                    <div className={styles.Gallery}>
                        <Gallery items={pictures}/>
                    </div>
                    }

                    <RandomImages images={randomImages}/>

                    {websiteLinks}

            <Draggable
                left={Math.random()}
                top={Math.random()}>
                <Button to={`/${locale}/career`} state={{selectedProject: slug}}>Join us!</Button>
            </Draggable>

                </div>
            )}
        </AppConsumer>
    )
}

Project.propTypes = {
    locale: PropTypes.string.isRequired,
    backButtonText: PropTypes.string,
    slug: PropTypes.string,
    headline: PropTypes.shape({
        text: PropTypes.string,
    }),
    text: PropTypes.shape({
        html: PropTypes.string,
    }),
    websites: PropTypes.array,
    pictures: PropTypes.array,
    facts: PropTypes.array,
    randomImages: PropTypes.array,
}

export default Project
