import React from 'react'
import styles from './Draggable.module.scss'
import PropTypes from 'prop-types'

class Draggable extends React.Component {

    constructor(props) {
        super(props)
        this.onStart = this.onStart.bind(this)
        this.onMove = this.onMove.bind(this)
        this.onEnd = this.onEnd.bind(this)
        this.onWindowMove = this.onWindowMove.bind(this)
        this.update = this.update.bind(this)
        this.onDragStart = this.onDragStart.bind(this)
        this.onClickStart = this.onClickStart.bind(this)

        const x = 0
        const y = 0
        this.start = {x, y}
        this.last = {x, y}
        this.delta = {x, y}
        this.position = {x, y}
        this.isMoved = false
    }

    componentDidMount() {
        window.addEventListener('touchmove', this.onWindowMove, {passive: false})

        this.node.addEventListener('touchstart', this.onStart)
        this.node.addEventListener('mousedown', this.onStart)
        this.node.addEventListener('dragstart', this.onDragStart)
        this.node.addEventListener('click', this.onClickStart)
        this.raf = requestAnimationFrame(this.update)

        const {left, top} = this.props

        const wrapperHeight = document.body.clientHeight
        const wrapperWidth = window.innerWidth
        const windowHeight = window.innerHeight
        const {width, height} = this.node.getBoundingClientRect()

        let x = wrapperWidth * left
        let y = wrapperHeight * top

        const diffX = x + width - wrapperWidth
        const diffY = y + height - wrapperHeight

        if (diffX > 0) x = Math.max(x - diffX, 0)
        if (diffY > 0) y = Math.max(y - diffY, 0)

        this.node.style.left = `${(x / wrapperWidth) * 100}vw`
        this.node.style.top = `${(y / windowHeight) * 100}vh`
        this.node.classList.add(styles.IsVisible)
    }

    componentWillUnmount() {
        window.removeEventListener('touchmove', this.onWindowMove)

        this.node.removeEventListener('touchstart', this.onStart)
        this.node.removeEventListener('mousedown', this.onStart)
        this.node.removeEventListener('dragstart', this.onDragStart)
        this.node.removeEventListener('click', this.onClickStart)
        cancelAnimationFrame(this.raf)
    }

    onStart(event) {
        this.isStarted = true

        const touches = event.touches ? event.touches[0] : event

        this.start.x = touches.pageX
        this.start.y = touches.pageY
        this.delta.x = 0
        this.delta.y = 0
        this.last.x = this.position.x
        this.last.y = this.position.y

        document.body.addEventListener('touchmove', this.onMove, {passive: false})
        document.body.addEventListener('mousemove', this.onMove, {passive: false})
        document.body.addEventListener('touchcancel', this.onEnd)
        document.body.addEventListener('touchend', this.onEnd)
        document.body.addEventListener('mouseup', this.onEnd)
        document.body.addEventListener('mouseleave', this.onEnd)

        this.node.classList.add(styles.IsDragging)
    }

    onMove(event) {
        if (!this.isStarted) return
        const touches = event.touches ? event.touches[0] : event

        this.delta.x = touches.pageX - this.start.x
        this.delta.y = touches.pageY - this.start.y

        this.position.x = this.last.x + this.delta.x
        this.position.y = this.last.y + this.delta.y
    }

    onEnd() {
        document.body.removeEventListener('touchmove', this.onMove)
        document.body.removeEventListener('mousemove', this.onMove)
        document.body.removeEventListener('touchcancel', this.onEnd)
        document.body.removeEventListener('touchend', this.onEnd)
        document.body.removeEventListener('mouseup', this.onEnd)
        document.body.removeEventListener('mouseleave', this.onEnd)

        this.node.classList.remove(styles.IsDragging)
        this.isStarted = false
        this.isMoved = Math.abs(this.delta.x + this.delta.y) > 0
    }

    onWindowMove(event) {
        if (this.isStarted) {
            event.preventDefault()
        }
    }

    onDragStart(event) {
        event.preventDefault()
    }

    onClickStart(event) {
        if (this.isMoved) {
            event.preventDefault()
            this.isMoved = false
        }
    }

    update() {
        this.raf = requestAnimationFrame(this.update)
        if (this.isStarted) this.node.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`
    }

    render() {
        const {children} = this.props

        return (
            <div className={styles.Main}
                 ref={node => this.node = node}
            >
                {children}
            </div>
        )
    }
}

Draggable.propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
}

export default Draggable