import React from 'react'
import styles from './RandomImages.module.scss'
import PropTypes from 'prop-types'
import FitPic from '../fitPic/FitPic'
import Draggable from '../draggable/Draggable'
import cx from 'classnames'
import {BASE_FONT_SIZE, MQ_TABLET} from '../../Config'

const shuffle = array => {
    let currentIndex = array.length
    let temporaryValue
    let randomIndex

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }

    return array
}

const RandomImages = (props) => {
    const images = shuffle(props.images)
    const isDesktop = typeof window !== 'undefined' && window.matchMedia(`(min-width: ${MQ_TABLET / BASE_FONT_SIZE}em)`).matches
    const leftOffset = isDesktop ? 0.75 : 0.5

    return images.map(({image}, index) => {
        if (!image.url) return null

        const {dimensions} = image
        const width = dimensions ? dimensions.width : 0
        const height = dimensions ? dimensions.height : 0
        const landscape = width > height

        const left = Math.random() * leftOffset
        const top = ((index + 1) / (images.length + 1))

        const sizes = `(min-width: ${MQ_TABLET / BASE_FONT_SIZE}em) ${landscape ? 25 : 21}vw, ${landscape ? 67 : 50}vw`

        return (
            <Draggable left={left} top={top} key={index}>
                <article className={cx(styles.Item, {[styles.Landscape]: landscape})}>
                    <FitPic
                      src={image.url}
                      width={width}
                      height={height}
                      srcSet={[320, 640, 960, 1280, 1440]}
                      sizes={sizes}
                      defaultWidth={540}
                    />
                </article>
            </Draggable>
        )
    })
}

RandomImages.propTypes = {
    images: PropTypes.array.isRequired
}

export default RandomImages