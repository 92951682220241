import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styles from './Headline.module.scss'
import whitelist from '../stylesets/whitelist.json'
import cx from 'classnames'

const STYLE_SETS = Object.keys(whitelist)

const getRandomStyleSet = (charCode, guard) => {
    const randomStyleSet = STYLE_SETS[Math.floor(STYLE_SETS.length * Math.random())]
    guard++

    if (guard > STYLE_SETS.length) return null
    if (whitelist[randomStyleSet].includes(charCode)) return randomStyleSet
    else return getRandomStyleSet(charCode, guard)
}

const getRandomCharIndex = (chars, randomChars) => {
    const index = Math.floor(Math.random() * chars.length)
    if (!randomChars.find(char => char.index === index)) return index
    else return getRandomCharIndex(chars, randomChars)
}

const toPaddedHexString = (number, length = 4) => {
    const string = number.toString(16)
    return '0'.repeat(length - string.length) + string
}

class Headline extends PureComponent {
    constructor() {
        super()
        this.charNodes = []
    }

    componentDidMount() {
        this.charNodes.forEach((node, index) => {
            const delay = index * 75
            setTimeout(() => node.classList.add(styles.IsActive), delay)
            setTimeout(() => node.classList.add(styles.IsFinished), delay + 750)
        })
    }

    renderAlternates(char) {
        const charCode = toPaddedHexString(char.charCodeAt(0))

        const alternates = Object.keys(whitelist).reduce((filtered, styleSet) => {
            if (whitelist[styleSet].includes(charCode)) {
                filtered.push(<span key={styleSet} className={cx(styles.Alternate, styles[styleSet])}>{char}</span>)
            }
            return filtered
        }, [])

        const length = alternates.length + 1

        const style = {
            animationTimingFunction: `steps(${length})`,
            animationDuration: `${length * 100}ms`
        }

        return (
            <span className={styles.Inner} style={style}>
                <span className={styles.Alternate}>{char}</span>
                {alternates}
                </span>
        )
    }

    renderChar(char, index, randomChar) {
        const styleSet = randomChar ? randomChar.set : null

        const classes = cx(
            styles.Wrapper,
            {[styles[styleSet]]: styleSet}
        )

        return (
            <span key={index} className={classes}
                  ref={node => this.charNodes.push(node)}>

                <span className={styles.Char}>{char}</span>
                {this.renderAlternates(char)}
            </span>
        )
    }

    renderWords(word, index) {
        const chars = word.split('')
        const styleSets = chars.map((char, index) => ({index, set: getRandomStyleSet(toPaddedHexString(char.charCodeAt(0)), 0)}))
        const filteredStyleSets = styleSets.filter(({set}) => set !== null)
        const numChars = Math.min(Math.floor(chars.length * 0.4), filteredStyleSets.length)
        const randomChars = []

        while (randomChars.length < numChars) {
            const index = getRandomCharIndex(chars, randomChars)
            const char = chars[index]
            const set = getRandomStyleSet(toPaddedHexString(char.charCodeAt(0)), 0)
            if (set) randomChars.push({index, set})
        }

        return (
            <span className={styles.Word} key={index}>
                {
                    chars.map((char, index) => this.renderChar(char, index, randomChars.find(item => item.index === index)))

                }
            </span>
        )
    }

    render() {
        const text = this.props.headline.text ? this.props.headline.text.toUpperCase() : ''
        const words = text.split(' ')

        return (
            <h1 className={styles.Main}>
                {
                    words.map((word, index) => this.renderWords(word, index))
                }
            </h1>
        )
    }
}

export default Headline

Headline.propTypes = {
    headline: PropTypes.shape({
        text: PropTypes.string
    })
}

Headline.defaultProps = {
    headline: {}
}